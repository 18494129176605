import {Component, useEffect, useState} from "react";

import {
    AutoComplete,
    Button,
    Card,
    ConfigProvider,
    Flex,
    Form,
    Input,
    Layout,
    message,
    Row,
    Select, Space, Spin,
    Table,
    Upload
} from "antd";
import Apiurl from "../ConstURL";
import axios, {options} from "axios";
import {
    FileExcelOutlined, InboxOutlined,UploadOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";


export default function Addtask() {
    const [form] = Form.useForm()

    const [currentValue, setCurrentValue] = useState('')
    const [SkuFindValue, setSkuFindValue] = useState('')
    const [DownloadId, setDownloadId] = useState('')
    const [AllTypes, setAllTypes] = useState([]);
    const [AllTypesMas, setAllTypesMas] = useState([]);

    const [Loading, setLoading] = useState(false);
    const [AllCount, setAllCount] = useState(0);



    const [fileList, setFileList] = useState([]);

    const handleFileChange = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
        console.log('Информация о файле:', info);
        const file = info.file.originFileObj;
        if (file) {
            console.log('Файл выбран:', file.name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;

                console.log(fileContent.split("\r\n"))
                setSkuFindValue(fileContent.split("\r\n").join(" "))
                console.log('Содержимое файла:', fileContent);
            };
            reader.onerror = (error) => {
                console.error('Ошибка при чтении файла:', error);
            };
            reader.readAsText(file);
        }
    };


    useEffect(() => {
        axios.get(Apiurl + "/all/types?token=" + localStorage.getItem("token")).then((res: any) => {


            var mas = []
            setAllTypesMas(res.data.Data)
            res.data.Data.forEach(item => {
                mas.push({label: item, value: item})
            })
            setAllTypes(mas)

        }).catch((err: Error) => {
            console.log(err)
        })


    }, []);


    return (<Flex vertical={false} justify={"center"} style={{paddingTop: "7px"}}>

            <Space type="flex" size='large' justify="center" align="center" direction="vertical"
                   style={{padding: "20px"}}>

                <Card style={{}}>
                    <Spin spinning={Loading} size={"large"}>
                        <Flex vertical={false} justify={"center"} style={{paddingTop: "0px"}}>

                            <div style={{fontSize: "1.5em"}} color={"white"}>Всего товара: {AllCount}</div>
                        </Flex>
                    </Spin>

                </Card>
                <AutoComplete
                    popupMatchSelectWidth={1200}
                    style={{
                        width: 1200,
                    }}
                    options={AllTypes}

                    onSelect={(value) => {
                        console.log(value)
                        setCurrentValue(value)
                    }}

                    onChange={(value) => {
                        const mas = [];
                        AllTypesMas.forEach(item => {
                            if (item.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                                mas.push({label: item, value: item})
                            }

                        })
                        setAllTypes(mas)
                        if (value==="") {
                            setCurrentValue(value)
                        }
                    }
                    }
                    // onSearch={(value)=>{
                    //
                    // }
                    // }
                    size="large"
                >
                    <Input.Search
                        allowClear
                        onSearch={() => {
                            setLoading(true)
                            setAllCount(0)
                            var checkc1= currentValue
                            const requestOptions = {
                                method: "POST",
                                data: SkuFindValue,
                                maxRedirects: 5
                            };
                            axios({
                                url: `${Apiurl}/all/items`,
                                params: {
                                    token: localStorage.getItem("token"),
                                    filter_category: checkc1
                                },
                                ...requestOptions
                            }).then((res: any) => {
                                if (res.status === 200) {
                                    setAllCount(res.data.Allcount);
                                    setLoading(false);
                                    setDownloadId(res.data.IdDownload)
                                } else {
                                    message.error("Ошибка в отправке запроса!");
                                }
                            }).catch((err: Error) => {
                                console.log(err);
                                setLoading(false);
                                message.error("Произошла ошибка при выполнении запроса");
                            });
                        }}
                        placeholder="Выберите категорию" enterButton/>
                </AutoComplete>
                <Input
                    placeholder="Введите SKU"
                    allowClear
                    value={SkuFindValue}
                    onChange={(value) => {
                        setSkuFindValue(value.target.value)
                    }}
                    style={{width: 475}}
                />
                {/*<RangePicker disabledTime={disabledRangeTime} format="DD.MM.YYYY"*/}
                {/*             onChange={onChangeFilterfilter_date}*/}
                {/*             presets={rangePresets}/>*/}
                <Upload
                    maxCount={1}
                    fileList={fileList}
                    customRequest={
                        (info) => {

                            setFileList([info.file]);
                        }}
                    beforeUpload={(file) => {
                        const isTXT = file.name.includes(".txt")
                        if (!isTXT) {
                            message.error(`${file.name} это не txt`);
                        }
                        return isTXT || Upload.LIST_IGNORE;
                    }}
                    onChange={handleFileChange} // Обработчик изменения файла
                    showUploadList={false} // Скрываем список загруженных файлов
                >
                    <Button icon={<UploadOutlined/>}>Импорт файла</Button>
                </Upload>
                {AllCount !== 0 && <Button type={"primary"} icon={<FileExcelOutlined/>}
                                           rel="noreferrer" htmlType={"a"} target="_blank"
                                           href={Apiurl + "/all/items/excel?" + new URLSearchParams({
                                               token: localStorage.getItem("token"),
                                               downloadid:DownloadId,
                                               filter_category: currentValue

                                           })}>Выгрузить Excel</Button>
                }
            </Space>
        </Flex>

    );

}